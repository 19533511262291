import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import Login from './views/login/Login.vue';
Vue.use(Router);

// 解决路由两次点击报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to)
}
// 懒加载路由
const Home = () => import("./views/App.vue");
const PassList = () => import("./views/mini/PassList.vue");
const PassDetail = () => import("./views/mini/PassDetail.vue");
const PassActive = () => import("./views/mini/PassActive.vue");
const Reward = () => import("./views/reward/Reward.vue");
const Me = () => import("./views/me/Me.vue");
const ProfileView = () => import("./views/me/ProfileView.vue");
const ProfileEdit = () => import("./views/me/ProfileEdit.vue");

const SignIn = () => import("./views/login/SignIn.vue");
const SignUp = () => import("./views/login/SignUp.vue");

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/pass', component: PassList },
        { path: '/pass/detail', name: '/pass/detail', component: PassDetail },
        { path: '/pass/active', name: '/pass/active', component: PassActive },
        { path: '/reward', component: Reward },
        { path: '/profile', component: Me },
        { path: '/profile/view', name: '/profile/view', component: ProfileView },
        { path: '/profile/edit', name: '/profile/edit', component: ProfileEdit },

        { path: '/sign-up', name: '/sign-up', component: SignUp },
        { path: '/sign-in', name: '/sign-in', component: SignIn },
        { path: '/login', component: Login },
        { path: '*', redirect: '/login' }
    ]
});

router.beforeEach(async (to, from, next) => {
    //to prevent loading animation keeps running when switching router
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
