<template>
    <div class="login-container">
        <div class="header-wrapper" :style="{backgroundImage: 'url('+ headerSrc +')' }">
            <div class="title-wrapper">
                <div>JOIN</div>
                <div>TO GET</div>
                <div>LOYALTY</div>
                <div>PASSES</div>
            </div>
        </div>
        <img class="center-wrapper-logo" v-if="sInfo.slogo" alt="logo" :src="sInfo.slogo">
        <div class="center-wrapper-info">{{sInfo.sname}}</div>
        <div class="btn-wrapper">
            <vButton :text="getSignText" @btnClick="signUpOrLogin" />
            <vButton v-if="!isUser()" text="Sign in" type="primary-o" @btnClick="signIn" />
            <div v-if="isUser()" class="right-enter" @click.prevent="signUp">not {{this.user.name}}?
                Sign up now!</div>
        </div>
        <div class="footer-wrapper">powered by minitable</div>
    </div>
</template>
<style scoped>
    .login-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
    }

    .header-wrapper {
        position: absolute;
        bottom: 295px;
        left: 0;
        width: 100%;
        background-color: #707070;
        background-size: cover;
    }

    .header-wrapper .title-wrapper {
        padding: 230px 37px 59px 36px;
        font-size: 56px;
        line-height: 52px;
    }

    .center-wrapper-logo {
        position: absolute;
        left: 152px;
        bottom: 259px;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        font-size: 14px;
        background-color: #eee;
    }

    .center-wrapper-info {
        position: absolute;
        bottom: 230px;
        left: 62.5px;
        width: 250px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        text-align: center;
    }

    .btn-wrapper {
        position: absolute;
        bottom: 46px;
        left: 37px;
        width: 302px;
        font-size: 16px;
    }

    .right-enter {
        font-size: 16px;
        color: #08bcaa;
        text-align: right;
        padding-top: 20px;
    }

    .footer-wrapper {
        position: absolute;
        bottom: 11px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 13px;
        color: #AAB0BB;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { LOGIN_BG_SRC } from '../../consts/const.js';
    import { isExistUser } from '../../kits/tools.js';
    import vButton from '../../components/Button.vue';

    export default {
        name: 'login',
        components: { vButton },
        data() {
            return {
                headerSrc: LOGIN_BG_SRC,
                signText: 'Sign up',
                invite_by: this.$route.query.invite_by || ''
            }
        },
        created() {
            let isStore = this.$route.query.sid;
            window.localStorage.setItem('system', JSON.stringify({ "isSystem": !isStore }));
            isStore && this.initsInfo({ data: { sid: this.$route.query.sid } });
            this.updateUser({ home: false }); // 更新是否运行进入主页的判断
        },
        mounted() {
        },
        computed: {
            ...mapGetters({
                sInfo: 'app/getsInfo',
                user: 'app/getUser',
                is_loading: 'app/getLoading',
            }),
            getSignText() {
                return `Sign${this.is_loading ? 'ing' : ''}${this.isUser() ? (' in as ' + this.user.name) : ' up'}${this.is_loading ? ' ...' : ''}`;
            },
        },
        methods: {
            ...mapActions({
                initsInfo: 'app/initsInfo',
                checkActive: 'app/checkActive',
                submitUser: 'app/submitUser',
            }),
            ...mapMutations({
                updateUser: 'app/SET_USER',
                setLoading: 'app/APP_SET_IF_LOADING'
            }),
            isUser() {
                return isExistUser(this.user);
            },
            signUpOrLogin() {
                if (!this.isUser()) {
                    this.signUp();
                    return;
                }

                if (!this.$route.query.sid) { // url没有传sid，则默认进系统主页
                    this.updateUser({ home: true });
                    this.$router.replace('/pass');
                    return;
                }

                if (!this.sInfo || (this.sInfo && !this.sInfo.sid) || !this.user || (this.user && !this.user.id) || this.is_loading) {
                    return;
                }

                this.setLoading({ is_loading: true });
                this.checkActive({
                    data: { sid: this.sInfo.sid, cid: this.user.id },
                    success: (check) => {
                        this.setLoading({ is_loading: false });
                        if (check.active) {
                            this.updateUser({ home: true });
                            this.$router.replace({
                                name: '/pass/detail',
                                query: { sid: this.sInfo.sid, cid: this.user.id },
                            });
                        } else {
                            this.$router.replace({
                                name: '/pass/active',
                                query: { sid: this.sInfo.sid, cid: this.user.id, invite_by: this.invite_by },
                            });
                        }
                    },
                    fail: () => {
                        this.setLoading({ is_loading: false });
                    }
                });
            },
            signIn() {
                this.$router.push({ name: '/sign-in', query: { sid: this.$route.query.sid, invite_by: this.invite_by } });
            },
            signUp() {
                this.$router.push({ name: '/sign-up', query: { sid: this.$route.query.sid, invite_by: this.invite_by } });
            }
        }
    };
</script>