import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    sInfo: {
        sId: null,
        title: '--',
        info: '--',
        logoSrc: '#',
    },
    loading: false,
    showSucModal: false
};

// getters
const getters = {
    getCusInfo(state) {
        return state.sInfo;
    },
    getLoading(state) {
        return state.loading;
    },
    getShowSucModal(state) {
        return state.showSucModal;
    }
}

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5966' : rootGetters.serverRoot();
        fetchApi(
            { commit },
            {
                request_name: 'get_sinfo',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    let vo = res.data.data;
                    commit('SET_CUS_INFO', {
                        sInfo:
                        {
                            title: vo.sname || '',
                            logoSrc: vo.slogo || '/',
                            info: vo.sdesc || '',
                            sId: post_data.sid
                        }
                    });
                } else {
                    console.log(e);
                }
            }).catch(() => {
                console.log(e);
            });
    },
    submitData({ commit, rootState, rootGetters }, post_data) {
        commit('SET_LOADING', { loading: true });
        let aj_host = rootState.debug ? 'http://127.0.0.1:5966' : rootGetters.serverRoot();
        fetchApi(
            { commit },
            {
                request_name: 'update',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('SET_LOADING', { loading: false });
                commit('SET_SHOW_SUC_MODAL', { showSucModal: true });
            }).catch((e) => {
                commit('SET_LOADING', { loading: false });
                console.log(e);
            });
    }
};

// mutations
const mutations = {
    SET_CUS_INFO(state, _data) {
        state.sInfo = _data.sInfo;
    },
    SET_SHOW_SUC_MODAL(state, _data) {
        state.showSucModal = _data.showSucModal;
    },
    SET_LOADING(state, _data) {
        state.loading = _data.loading;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
