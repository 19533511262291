import fetchApi from '../../kits/newFetch';

const debugHost = 'http://127.0.0.1:6161';
const test_user = { "code": 0, "data": { "code": 100000, "msg": "", "data": { "mid": "1607184262886", "name": "Jean Zhao", "phone": "+8618721704046", "email": "jeanzhao@aliyun.com", "detail": { "other": "", "can": "" }, "id": "e04aef43-ced8-464c-bf76-8af0d6685e30", "createdate": "2020-12-05T16:04:25.372Z" } } };

// initial state
const state = {
    sInfo: {
        sname: '',
        slogo: '',
        sdesc: '',
        spic: '',
        saddress: '',
        sid: JSON.parse(window.localStorage.getItem('sid'))
    },
    user: JSON.parse(window.localStorage.getItem('user') || '{}'),
    is_loading: false,
    errMsg: null
};

// getters
const getters = {
    getsInfo(state) {
        return state.sInfo;
    },
    getUser(state) {
        return state.user;
    },
    getLoading(state) {
        return state.is_loading;
    },
    getMsg(state) {
        return state.errMsg;
    }
};

// actions
const actions = {
    initsInfo({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_sinfo',
                params: postBody.data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    if (postBody.success) postBody.success();
                    let vo = res.data.data;
                    commit('SET_SINFO', {
                        sInfo:
                        {
                            sname: vo.sname || '',
                            slogo: vo.slogo || '/',
                            sdesc: vo.sdesc || '',
                            spic: vo.spic || '',
                            saddress: vo.saddress || '',
                            sid: postBody.data.sid
                        }
                    });
                } else {
                    console.log(res);
                    if (postBody.fail) postBody.fail(e);
                }
            }).catch((e) => {
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initLoyaltyUserInfo({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_user_info',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data) {
                    let user = { ...res.data.data, home: true };
                    commit('SET_USER', { user });
                    if (postBody.success) postBody.success({ ...user, sId: rootState.app.sInfo.sid });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    submitUser({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_user_info',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data && res.data.code === 100000) {
                    let user = { ...res.data.data };
                    commit('SET_USER', { user });
                    if (postBody.success) postBody.success({ ...user, sid: rootState.app.sInfo.sid, cid: user.id });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    verifyUser({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'verify_user',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data && res.data.code === 100000) {
                    let user = { ...res.data.data[0] };
                    commit('SET_USER', { user });
                    if (postBody.success) postBody.success({ ...user, sid: rootState.app.sInfo.sid, cid: user.id });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    checkActive({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'is_active_by_sid_cid',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                if (postBody.success) postBody.success({ active: res && res.data && res.data.data && res.data.data.length > 0 });
            }).catch((e) => {
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    activeCard({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'active',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                if (postBody.success) postBody.success({ active: res && res.data && res.data.data && res.data.data.id });
            }).catch((e) => {
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initCardList({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'get_loyalty_list',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ list: res && res.data && res.data.data && res.data.data ? res.data.data : [] });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initOrderHList({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'get_loyalty_log',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ list: res && res.data && res.data.data && res.data.data ? res.data.data : [] });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    checkSignStatusByCard({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'check_sign_by_loyalty',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                let resObj = res && res.data && res.data.data ? res.data.data : {};
                if (postBody.success) postBody.success({ ...resObj });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    signByCard({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'sign_by_loyalty',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ success: res && res.data && res.data.data });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initCardDetail({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'get_loyalty_detail',
                params: postBody.data,
                aj_host
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ item: res.data.data && res.data.data[0] ? res.data.data[0] : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
            });
    },

};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_MSG(state, _data) {
        state.errMsg = _data.msg;
    },
    SET_SINFO(state, _data) {
        state.sInfo = _data.sInfo;
        window.localStorage.setItem('sid', JSON.stringify(_data.sInfo.sid));
    },
    SET_USER(state, _data) { // 更新用户登录状态
        let newData = _data.user ? _data.user : { ...state.user, home: !!_data.home };
        state.user = newData;
        window.localStorage.setItem('user', JSON.stringify(newData));
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
