<template>
    <div class="tab-bar-item" @click="itemClick">
        <div v-if="!isActive">
            <slot name="item-icon"></slot>
        </div>
        <div v-else>
            <slot name="item-icon-active"></slot>
        </div>
        <div>
            <slot name="item-text"></slot>
        </div>
    </div>
</template>
<style>
    .tab-bar-item {
        flex: 1;
        font-size: 16px;
    }

    .tab-bar-item img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-top: 18.4px;
    }
</style>
<script>
    export default {
        name: 'v-tabbar-item',
        props: {
            link: String,
            activeColor: {
                type: String,
                default: "red"
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
            isActive() {
                return this.$route.path.indexOf(this.link) !== -1;
            }
        },
        methods: {
            itemClick(item, i) {
                this.$router.push(this.link);
            }
        }
    };
</script>