const isWeixin = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
}

const isIOS = () => {
    return navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
}

const OS = () => {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet, // 平板
        isPhone: isPhone,   //ios手机
        isAndroid: isAndroid, //安卓手机
        isPc: isPc //pc端
    };
}

const loadImg = (src) => {
    return new Promise((rs, rj) => {
        const img = new Image();
        img.onload = rs;
        img.onerror = rj;
        img.src = src;
    });
}

const isExistUser = (user) => {
    return user && user.name && user.name !== 'undefined';
}

const getUserName = (user) => {
    return isExistUser(user) ? user.name : '--';
}

export {
    // OS
    isWeixin,
    isIOS,
    OS,
    loadImg,

    // logic
    isExistUser,
    getUserName,
}

