import querystring from 'querystring';

const interface_list = {
    get_sinfo: { url: '/weapp/store/get', method: 'get' }, // 获取店铺信息
    update_user_info: { url: '/weapp/loyalty/customer/create', method: 'post' }, // 登录or注册or更新注册信息
    verify_user: { url: '/weapp/loyalty/customer/verify', method: 'post' }, // 用户登录验证
    trigger_sms: { url: '/weapp/loyalty/sms', method: 'post' }, // 获取短信验证码
    verify_sms: { url: '/weapp/loyalty/sms/verify', method: 'post' }, // 短信验证码校验

    is_active_by_sid_cid: { url: '/weapp/loyalty/members', method: 'get' }, // 是否已激活会员卡
    active: { url: '/weapp/loyalty/member/create', method: 'post', postjson: true }, // 激活会员卡

    get_loyalty_list: { url: '/weapp/loyalty/customer/members', method: 'get' }, // 获取会员卡列表
    get_loyalty_detail: { url: '/weapp/loyalty/members', method: 'get' }, // 会员卡详情
    get_loyalty_log: { url: '/weapp/loyalty/member/log', method: 'get' }, // 历史订单


    get_user_info: { url: '/weapp/loyalty/customers', method: 'get' }, // 获取我的信息

    check_sign_by_loyalty: { url: '/weapp/loyalty/checkin/verify', method: 'post' }, // 获取当前会员卡是否已签到
    sign_by_loyalty: { url: '/weapp/loyalty/checkin', method: 'post' }, // 当前会员卡签到

};

function fetchApi({ request_name, params, customHeaders = {}, aj_host = '' }, { commit }) {
    const conf = JSON.parse(JSON.stringify(interface_list[request_name]));
    const token = window.localStorage.getItem('token') || '';

    if (!conf) {
        return Promise.reject({
            code: 0,
            msg: '',
            data: {
                code: 100010,
                msg: 'no ajax register',
                data: {}
            }
        });
    }

    let headerSettings = {
        'Content-Type': conf.postjson ? 'application/json;charset=utf-8' : 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': 'Bearer ' + token,
    };

    Object.keys(customHeaders).forEach((k) => {
        headerSettings[k] = customHeaders[k];
    });
    const headers = new Headers(headerSettings);

    const fetch_optins = {
        method: conf.method,
        headers,
        credentials: 'omit'
    };

    if (conf.method == 'get') {
        conf.url = `${conf.url}${querystring.stringify(params) ? '?' + querystring.stringify(params) : ''}`;
    } else if (conf.method == 'post') {
        let tmp = Object.keys(params).length > 0 ? Object.keys(params).map((key) =>
            conf.postjson ? `"${encodeURIComponent(key)}":"${encodeURIComponent(params[key])}"`
                : `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`) : [],

            searchParams = tmp.length > 0 ? (conf.postjson ? `{${tmp.join(',')}}` : tmp.join('&')) : '';

        fetch_optins.body = searchParams;
    }

    return fetch(aj_host + conf.url, fetch_optins)
        .then((response) => {
            if (response.status >= 400) {
                throw new Error('Bad response from server');
            }
            return response.json();
        })
        .then((data) => {
            if (data.code == -1) {
                return Promise.reject({
                    code: 0,
                    msg: '',
                    data: {
                        code: 100010,
                        msg: data.error && typeof data.error !== 'string' && JSON.parse(data.error) && JSON.parse(data.error).body ? JSON.parse(data.error).body.message : (data.error || 'Server error.'),
                        data: {}
                    }
                });
            }

            if (data.data.code === 100000) {
                if (data.data.data.Authorization) {
                    let token = data.data.data.Authorization.split(' ')[1];
                    if (token) {
                        window.localStorage.setItem('token', token);
                    }
                }
                return Promise.resolve(data);
            } else if (data.data.code === 100002 || data.data.code === 100020) { // 100002: 登录失效；100020：token异常
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('user'); // fix: 登录失效回调login页面不能提供sign in入口
                commit && commit('app/SET_USER', { user: { home: false } }, { root: true }); // remove store.user
                const system = JSON.parse(window.localStorage.getItem('system') || '{}');
                window.location.replace(`#/login${system.isSystem ? '' : '?sid=' + JSON.parse(window.localStorage.getItem('sid'))}`);
                return Promise.reject(data);
            } else {
                return Promise.reject(data);
            }
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

let msgResetTimer;
const handleMsgReset = (commit) => {
    if (msgResetTimer) {
        clearTimeout(msgResetTimer);
    }
    msgResetTimer = setTimeout(() => {
        commit && commit('app/APP_SET_MSG', { msg: undefined }, { root: true });
        msgResetTimer && clearTimeout(msgResetTimer);
    }, 3000);
};
export default ({ commit }, opts) => {
    return fetchApi(opts, { commit })
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((res) => {
            const defaultMsg = 'Unknown error occurs.', msg = !res ? defaultMsg : (res.data && res.data.msg ? res.data.msg : (res.error || res || defaultMsg));
            commit && commit('app/APP_SET_MSG', { msg, type: 'error' }, { root: true });
            handleMsgReset(commit);
            return Promise.reject(res);
        });
};
