<template lang="html">
    <div id="app">
        <router-view></router-view>
        <vTabBar v-if="user.home">
            <vTabBarItem link="/pass">
                <img :src="PASS_SRC" slot="item-icon" alt />
                <img :src="PASS_ACTIVE_SRC" slot="item-icon-active" alt />
                <div slot="item-text">PASS</div>
            </vTabBarItem>
            <!-- <vTabBarItem link="/reward">
                        <img :src="REWARD_SRC" slot="item-icon" alt />
                        <img :src="REWARD_ACTIVE_SRC" slot="item-icon-active" alt />
                        <div slot="item-text">REWARD</div>
                    </vTabBarItem> -->
            <vTabBarItem link="/profile">
                <img :src="ME_SRC" slot="item-icon" alt />
                <img :src="ME_ACTIVE_SRC" slot="item-icon-active" alt />
                <div slot="item-text">ME</div>
            </vTabBarItem>
        </vTabBar>
        <div v-if="msg" class="msg-box"><span class="msg-type">Error!</span> {{msg}}</div>
    </div>
</template>
<style>
    html {
        font-family: Arial;
    }

    .msg-box {
        z-index: 9999;
        position: fixed;
        top: 30px;
        left: 80px;
        right: 80px;
        width: 215px;
        height: auto;
        font-size: 14px;
        display: flex;
        justify-content: center;
        padding: 10px;
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .msg-type {
        font-weight: bold;
        margin-right: 10px;
    }

    .pc {
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        transform: translateX(-50%);
        width: 385px;
        max-width: 385px;
    }

    .bullet-active {
        background-color: #08bcaa !important;
        opacity: 1 !important;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { OS } from '../kits/tools.js';
    import { PASS_SRC, PASS_ACTIVE_SRC, REWARD_SRC, REWARD_ACTIVE_SRC, ME_SRC, ME_ACTIVE_SRC, PRO_SET_SRC } from '../consts/const.js';
    import vFormPage from '../components/Formpage.vue';
    import vTabBar from '../components/TabBar.vue';
    import vTabBarItem from '../components/TabBarItem.vue';

    export default {
        name: 'v-pass-home',
        components: { vTabBar, vTabBarItem },
        data() {
            return {
                PASS_SRC, PASS_ACTIVE_SRC, REWARD_SRC, REWARD_ACTIVE_SRC, ME_SRC, ME_ACTIVE_SRC, PRO_SET_SRC
            }
        },
        created() {
        },
        mounted() {
            if (OS().isPc) {
                document.querySelector('#app').classList.add("pc");
            } else {
                document.querySelector('#app').classList.remove("pc");
            }
        },
        computed: {
            ...mapGetters({
                user: 'app/getUser',
                msg: 'app/getMsg'
            }),
        },
        methods: {
        }
    };
</script>