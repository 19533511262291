<template>
    <div id="tab-bar">
        <div class="tab-bar-content">
            <slot></slot>
        </div>
    </div>
</template>
<style>
    #tab-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 5px;
        box-sizing: border-box;
        box-shadow: 0 -1px 1px rgba(100, 100, 100, 0.2);
    }
    .tab-bar-content {
        display: flex;
        text-align: center;
        background-color: #fff;
        width: 100%;
        height: 79.5px;
    }
</style>
<script>
    export default {
        name: 'v-tabbar',
        props: {
        },
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
        }
    };
</script>